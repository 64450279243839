<template>
    <span v-if="form && (loggedin || form.code.trim() != '')">
        <v-card elevation="4" class="mt-5 pa-3 mx-auto" max-width="1200px">
            <v-img class="mx-auto" v-if="form.logo" :src="form.logo" contain style="width: 70%; max-height: 500px;"></v-img>
            <h1 class="mb-5 text-center" v-text="form.title"></h1>
            <v-simple-table v-if="formResponse"  v-for="section,index in formResponse.sections" class="mx-1 mx-md-3 mx-lg-10">
                <tfoot>
                    <tr v-if="section.show == undefined || section.show">
                        <th colspan="2" style="height: 50px;">
                        </th>
                    </tr>
                    <tr v-if="section.questions.length == 0">
                        <th colspan="3" class="py-2">
                            <h1 class="text-left" :style="form.color? ('color:'+form.color) : 'color:black;'" v-text="section.name"></h1>
                        </th>
                    </tr>
                    <tr v-else-if="section.show == undefined || section.show" >
                        <th colspan="2" class="py-2" :style="form.color? ('background-color:'+form.color) : 'background-color:var(--v-primary-base)'">
                            <h1 class="white--text text-left" v-text="section.name"></h1>
                        </th>
                    </tr>
                    <tr v-for="question, index in section.questions">
                        <th class="py-3" v-if="question.type =='slider'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="d-flex flex-row align-center justify-center">
                                <v-slider :color="form.color? form.color : 'primary'" :track-color="form.color? form.color : 'primary'" class="pr-5 pt-6 pb-4" min="0" max="4" thumb-label ticks="always" tick-size="3" hide-details v-model="question.answer" @change="updateAnswer">
                                    <template v-slot:thumb-label>
                                        <v-img height="30px" width="30px" :src="require(`@/assets/images/smiley/smiley ${smileyOrder[question.answer]}.svg`)"></v-img>
                                    </template>
                                </v-slider>
                                <span>
                                    <v-img height="50px" width="50px" :src="require(`@/assets/images/smiley/smiley ${smileyOrder[question.answer]}.svg`)"></v-img>
                                </span>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='short'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="d-flex flex-row align-center justify-center">
                                <v-text-field :color="form.color? form.color : 'primary'" label="Réponse" v-model="question.answer"></v-text-field>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='long'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="d-flex flex-row align-center justify-center">
                                <v-textarea :color="form.color? form.color : 'primary'" rows="3" label="Réponse" v-model="question.answer"></v-textarea>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='listeUnique'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="d-flex flex-row align-center justify-center">
                                <v-select :color="form.color? form.color : 'primary'" :items="question.choices" v-model="question.answer"></v-select>
                            </span>
                        </th>
                        <th class="py-3" v-if="question.type =='listeMultiple'">
                            <h3>
                                <span v-text="question.title"></span>
                                <span v-if="question.required" class="accent--text"> *</span>
                            </h3>
                            <span class="d-flex flex-row align-center justify-center">
                                <v-select :items="question.choices" v-model="question.answer" multiple></v-select>
                            </span>
                        </th>
                    </tr>
                </tfoot>
            </v-simple-table>
            <span class="py-5 d-flex flex-row align-center justify-center" v-if="formResponse.sections.length > 0">
                <v-btn :color="form.color? form.color : 'primary'" :style="form.textColor? ('color :' + form.textColor + ';') : ''" x-large @click="sendResponse">Envoyer mes Réponses</v-btn>
            </span>
        </v-card>
        <v-dialog v-if="editForm" v-model="editDialog" persistent max-width="1000px" transition="dialog-transition">
            <v-card>
                <v-card-title>
                    <span class="headline">Editer le formulaire</span>
                </v-card-title>
                <v-card-text>
                    <v-switch label="passer en marque blanche" v-model="styleEnabled"></v-switch>

                    <v-text-field v-if="styleEnabled" label="Logo" v-model="editForm.logo"></v-text-field>
                    <v-row v-if="styleEnabled">
                      <v-col cols="12" md="6" class="d-flex align-center justify-center">
                        <h3>Couleure primaire : </h3>
                        <input  type="color" v-model="editForm.color" mode="hex" ></input>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex align-center justify-center">
                        <h3>Couleure du text : </h3>
                        <input type="color" v-model="editForm.textColor" mode="hex" ></input>
                      </v-col>
                    </v-row>

                    <v-text-field label="Titre" v-model="editForm.title"></v-text-field>

                    <v-text-field label="Code" v-model="editForm.code"></v-text-field>
                    <p>Le formulaire passera automatiquement en mode privé si aucun code n'est saisi.</p>
                </v-card-text>
                <v-card-text v-for="section,index in editForm.sections">
                    <v-divider :style="'border: 2px '+form.color+' solid;'"></v-divider>
                    <span class="pt-5 d-flex flex-row align-center justify-space-between">
                        <v-text-field label="section" v-model="section.name" ></v-text-field>
                        <v-btn icon @click="moveSectionUp(index)" v-if="index > 0">
                            <v-icon color="blue">mdi-arrow-up-bold</v-icon>
                        </v-btn>
                        <v-btn icon @click="moveSectionDown(index)" v-if="index < editForm.sections.length-1">
                            <v-icon color="blue">mdi-arrow-down-bold</v-icon>
                        </v-btn>
                        <v-switch v-model="section.show" color="blue"></v-switch>
                        <v-btn icon @click="removeSection(index)">
                            <v-icon color="accent">mdi-delete-outline</v-icon>
                        </v-btn>
                    </span>
                    <span class="pt-5 d-flex flex-row align-center justify-start">
                        <v-divider vertical></v-divider>
                        <span class="pl-12" style="width: 100%;">
                            <div v-for="question,questionIndex in section.questions">
                                <v-divider></v-divider>
                                <span class="pt-5 d-flex flex-row align-center justify-space-between">
                                    <h3 v-text="'Question n°'+ (questionIndex+1) +' :'"></h3>
                                    <span>
                                      <v-btn icon @click="moveQuestionUp(section,questionIndex)" v-if="questionIndex > 0">
                                        <v-icon color="green">mdi-arrow-up-bold</v-icon>
                                      </v-btn>
                                      <v-btn icon @click="moveQuestionDown(section,questionIndex)" v-if="questionIndex < section.questions.length-1">
                                          <v-icon color="green">mdi-arrow-down-bold</v-icon>
                                      </v-btn>
                                      <v-btn icon @click="removeQuestion(section,questionIndex)">
                                          <v-icon color="accent">mdi-delete-outline</v-icon>
                                      </v-btn>
                                    </span>
                                </span>
                                <v-text-field label="Question" v-model="question.title"></v-text-field>
                                <div class="d-flex flex-row align-center justify-start">
                                    <div style="width:100%">
                                        <v-select class="py-0 my-0" :items="questionType" item-text="label" item-value="type" v-model="question.type" @change="updateAnswer"></v-select>
                                    </div>
                                    <div class="pl-3" v-if="question.type != 'slider'">
                                        <v-switch v-model="question.required" label="Obligatoire"></v-switch>
                                    </div>
                                </div>
                                <div class="d-flex flex-row align-center justify-start" v-if="question.type == 'listeUnique' || question.type == 'listeMultiple'">
                                    <v-combobox v-model="question.choices" chips clearable multiple filled rounded hint="Taper votre choix puis appuyer sur entrer pour l'ajouter">
                                        <template v-slot:selection="{ attrs, item, select, selected }">
                                            <v-chip small v-bind="attrs" :input-value="selected" close @click:close="removeChoice(question, item)">
                                                {{ item }}
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </div>
                            </div>
                        </span>
                    </span>
                    <div class="pt-5 d-flex align-center justify-center">
                        <v-btn color="orange" outlined @click="addQuestion(section)">Ajouter une question</v-btn>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pt-5 d-flex align-center justify-center">
                    <v-btn class="mx-2" color="indigo" outlined @click="addSection">Ajouter une section</v-btn>
                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="exitEditModal">Annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="saveEditModal">Sauvegarder</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="shareDialog" persistent max-width="500px" transition="dialog-transition">
            <v-card>
                <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="shareDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex flex-column align-center justify-center">
                        <span class="px-3 py-2" v-if="form.code.trim() != ''" >
                            <img :src="getQRUrl" style="max-width: 200px;max-height: 200px;"/> 
                        </span>
                        <h2 class="py-2 text-center" v-text="form.title"></h2>
                        <h3 class="py-2 text-center" v-if="form.code.trim() != ''" v-text="'Code publique : ' + form.code"></h3>
                        <h3 class="py-2 text-center" v-else>Ce formulaire est privé</h3>
                        <span class="text-center mt-5" v-if="form.code.trim() != ''">
                            <v-btn color="primary" class="white--text" @click="copyFormLink">Copier le lien</v-btn>
                        </span>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </span>
    <span v-else>
        <v-card elevation="4" class="mt-5 pa-3 mx-auto" max-width="1200px">
            <v-card-title primary-title class="justify-center">
                <h2>Ce formulaire n'est pas disponible pour le moment !</h2>
            </v-card-title>
            <v-card-text class="my-12 d-flex align-center justify-center">
                <v-btn color="primary" outlined @click="$router.push('/home')">Retourner a l'accueil</v-btn>
            </v-card-text>
        </v-card>
    </span>
</template>

<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th:not(.v-data-table__mobile-row) {
    border: none;
}
</style>

<script>
import Form from '@/classes/Form.js'
import Response from '@/classes/Response.js'

import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
    name: "Form",
    props : ['loggedin'],
    data() {
        return {
            unsub : [],
            form : null,
            editForm : null,
            smileyOrder: ['R', 'O', 'J', 'VJ', 'VV'],
            editDialog : false,
            shareDialog : false,
            styleEnabled : false,
            questionType : [
                {
                    type : 'slider',
                    label : 'Satisfaction',
                },
                {
                    type : 'short',
                    label : 'Réponse courte',
                },
                {
                    type : 'long',
                    label : 'Réponse longue',
                },
                {
                    type : 'listeUnique',
                    label : 'Choix unique',
                },
                {
                    type : 'listeMultiple',
                    label : 'Choix multiple',
                },
            ],
            formResponse : {},
        }
    },
    watch : {
        '$route' : {
            handler : function(){
                this.unsub.push(Form.listenById(this.$route.params.id, (form) => {
                    this.form = form
                    this.formResponse.form = form.id
                    this.formResponse.sections = JSON.parse(JSON.stringify(form.sections))
                    for(let section of this.formResponse.sections){
                        for(let question of section.questions){
                            if(question.type == 'slider')
                                question.answer = 2
                            else{
                                question.answer = ""
                            }
                        }
                    }
                    if(this.form.logo || this.form.color || this.form.textColor){
                      this.styleEnabled = true
                    }else{
                      this.styleEnabled = false
                    }
                    this.$forceUpdate()
                    if(this.form.logo){
                      this.$emit('toggleLogo', false)
                    }else{
                      this.$emit('toggleLogo', true)
                    }
                }))
            },
            immediate : true
        }
    },
    computed : {
        getQRUrl(){
            return 'https://api.qrserver.com/v1/create-qr-code/?data='+ window.location.origin + '/form/' + this.form.id
        }
    },
    methods : {
        updateAnswer(){
            this.$forceUpdate()
        },
        triggerShare(){
            this.shareDialog = true
        },
        triggerEdit(){
            this.editForm = {}
            this.editForm.title = this.form.title
            this.editForm.code = this.form.code
            this.editForm.sections = JSON.parse(JSON.stringify(this.form.sections))
            if(this.styleEnabled){
                this.editForm.logo = this.form.logo
                this.editForm.color = this.form.color
                this.editForm.textColor = this.form.textColor
            }else{
                this.editForm.logo = false
                this.editForm.color = false
                this.editForm.textColor = false
            }
            this.editDialog = true
        },
        triggerCopy(){
            this.form.id = null
            this.editForm = {}
            this.editForm.title = this.form.title + ' (copie)'
            this.editForm.code = this.form.code + ' (copie)'
            this.editForm.sections = JSON.parse(JSON.stringify(this.form.sections))
            this.editForm.logo = this.form.logo
            this.editForm.color = this.form.color
            this.editForm.textColor = this.form.textColor
            this.editDialog = true
        },
        triggerDelete(){
            Swal.fire({
                title: 'Êtes-vous sûr ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Supprimer',
                cancelButtonText: 'Annuler',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.form.delete()
                    this.form = null
                }
            })
        },
        triggerResponses(){
            this.$router.push('/responses/'+this.form.id)
        },
        exitEditModal(){
            this.editDialog = false
        },
        removeSection(index){
            this.editForm.sections.splice(index, 1)
            this.$forceUpdate()
        },
        moveSectionUp(index){
            if(index > 0){
                let temp = this.editForm.sections[index]
                this.editForm.sections[index] = this.editForm.sections[index - 1]
                this.editForm.sections[index - 1] = temp
                this.$forceUpdate()
            }
        },
        moveSectionDown(index){
            if(index < this.editForm.sections.length - 1){
                let temp = this.editForm.sections[index]
                this.editForm.sections[index] = this.editForm.sections[index + 1]
                this.editForm.sections[index + 1] = temp
                this.$forceUpdate()
            }
        },
        saveEditModal(){
            Swal.fire({
                title: 'Attention cela engendrera la suppression de toutes les réponses !',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.form.title = this.editForm.title
                    this.form.code = this.editForm.code
                    this.form.sections = JSON.parse(JSON.stringify(this.editForm.sections))
                    if(this.styleEnabled){
                        this.form.logo = this.editForm.logo
                        this.form.color = this.editForm.color
                        this.form.textColor = this.editForm.textColor
                    }else{
                        this.form.logo = false
                        this.form.color = false
                        this.form.textColor = false
                    }
                    this.form.save()
                    this.editForm = null
                    this.editDialog = false
                    let responses = await Response.getByForm(this.form.id)
                    for(let response of responses){
                        response.delete()
                    }
                }
            })
        },
        addQuestion(section){
            section.questions.push({
                type : 'slider',
                title : '',
                required : true,
                choices : [],
            })
            this.$forceUpdate()
        },
        removeChoice(question, item){
            let index = question.choices.indexOf(item)
            question.choices.splice(index, 1)
            this.$forceUpdate()
        },
        removeQuestion(section,index){
            section.questions.splice(index, 1)
            this.$forceUpdate()
        },
        moveQuestionUp(section, index){
            if(index > 0){
                let temp = section.questions[index]
                section.questions[index] = section.questions[index - 1]
                section.questions[index - 1] = temp
                this.$forceUpdate()
            }
        },
        moveQuestionDown(section, index){
            if(index < section.questions.length - 1){
                let temp = section.questions[index]
                section.questions[index] = section.questions[index + 1]
                section.questions[index + 1] = temp
                this.$forceUpdate()
            }
        },
        addSection(){
            this.editForm.sections.push({
                name : 'Nouvelle section',
                show : true,
                questions : [],
            })
            this.$forceUpdate()
        },
        sendResponse(){
            let isFullFilled = true
            for(let section of this.formResponse.sections){
                for(let question of section.questions){
                    if(question.type != 'slider' && question.required && question.answer == ""){
                        isFullFilled = false
                        break
                    }
                }
            }
            if(isFullFilled){
                let response = new Response(null,this.formResponse.form,this.formResponse.sections)
                response.save()
                Swal.fire({
                    title: 'Merci !',
                    text: 'Votre réponse a bien été enregistrée !',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/home')
                    }
                })
            }else{
                Swal.fire({
                    title: 'Attention !',
                    text: 'Vous n\'avez pas répondu à toutes les questions obligatoires !',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                })
            }
        },
        copyFormLink(){
            let url = window.location.origin + '/form/' + this.form.id
            navigator.clipboard.writeText(url)
        },
    },
}
</script>
